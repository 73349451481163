import { promptForMemberJWT } from "../services/auth.service";
import { logCustomEvent, logLinkClick, logMemberValidationPrompt } from "./logging.utils";
import { navigateToUrl } from "./navigation.utils";

export const executeButtonAction = (e,props,pageContext,componentContext,customEventType,customEventData)=>{

  // Prevent default, to delay the navigation
  // We'll manually navigate after the event
  // has been logged.
  e.preventDefault();
  try{e.nativeEvent.stopImmediatePropagation();}catch(ee){}
  e.stopPropagation();


  var vendorName = pageContext.vendorName;

  if(props?.vendorName?.label&&props.vendorName.label.trim().length>0)vendorName=props.vendorName.label;


  var promise = null;

  if(customEventData&&vendorName&&customEventData.vendor_name==null){
    customEventData.vendor_name=vendorName;
  }
  
  if(customEventType&&customEventData) promise = logCustomEvent(pageContext.site,pageContext.domain,pageContext.pageName,props.label,customEventType,customEventData);
  else promise = logLinkClick(pageContext.site,pageContext.domain,pageContext.pageName,props.label,props.href,vendorName);


  promise.then(res=>{
          // If member validation is required
          if(props.requiresMemberValidation && props.vendorId){

            logMemberValidationPrompt(pageContext.site,pageContext.domain,pageContext.pageName,{
                
                    vendor_code:"",
                    member_validation_type:"Member Name",
                    vendor_name: vendorName,
                    business_line_product: pageContext.businessLineProduct,
                    business_line: pageContext.businessLine
                  
                  })

                const logInitialAttemptPromiseHandler = (data)=>{
                  
  
                    logCustomEvent(pageContext.site,pageContext.domain,pageContext.pageName,"member_validation","member_validation",{
                        member_validation_type:data.validationType,
                        business_line_product: pageContext.businessLineProduct,
                        business_line: pageContext.businessLine,
                        vendor_name: vendorName
                    });
                  }

              const logEventPromiseHandler = (data)=>{
                

                  logCustomEvent(pageContext.site,pageContext.domain,pageContext.pageName,"member_validation_return","member_validation_return",{
                      member_validation_type:data.validationType||"Member Name",
                      business_line_product: pageContext.businessLineProduct,
                      business_line: pageContext.businessLine,
                      membership_number : data.number,
                      form_submit_status:"success",
                      vendor_name: vendorName
                  });
                }

                
              const navigatePromiseHandler = (data)=>{


                var ets = "";

                // Check the page context for ets value
                if(pageContext&&pageContext.ets)ets=pageContext.ets;

                  // Substitute member validation variables
                  var reLocationHref = props.href
                      .replace(/MEMBER_NUMBER_PLACEHOLDER/g,data.number)
                      .replace(/FIRST_NAME_PLACEHOLDER/g,data.firstName)
                      .replace(/LAST_NAME_PLACEHOLDER/g,data.lastName)
                      .replace(/CLUB_CODE_PLACEHOLDER/g,data.club)
                      .replace(/VENDOR_PLACEHOLDER/g,data.vendor)
                      .replace(/POSTAL_CODE_PLACEHOLDER/g,data.zipCode)
                      .replace(/COUPON_CODE_PLACEHOLDER/g,data.couponCode)
                      .replace(/PROMO_CODE_PLACEHOLDER/g,data.promoCode)
                      .replace(/VALID_THROUGH_PLACEHOLDER/g,data.expirationDate)
                      .replace(/LEVEL_PLACEHOLDER/g,data.level)
                      .replace(/ETS_PLACEHOLDER/g,ets);


                      logCustomEvent(pageContext.site,pageContext.domain,pageContext.pageName,"member_validation_complete","member_validation_complete",{
                        member_validation_type:data.validationType || "Member Name",
                        business_line_product: pageContext.businessLineProduct,
                        business_line: pageContext.businessLine,
                        vendor_name: vendorName,
                        destination_url: reLocationHref
                        
                    }).then(r=>{
                        
                    navigateToUrl(reLocationHref,pageContext,componentContext,props,data);

                    });
              }


              const promiseErrorHandler = (e)=>{

                logCustomEvent(pageContext.site,pageContext.domain,pageContext.pageName,"member_validation_return","member_validation_return",{
                    member_validation_type:e.validationType||"Member Name",
                    business_line_product: pageContext.businessLineProduct,
                    business_line: pageContext.businessLine,
                    form_submit_status:"fail",
                    vendor_name: vendorName});
                }
              
              var promises = promptForMemberJWT(props.vendorId)

              // We'll have two promiese
              // Promise 1 is for closing the box
              // Promise 2 is for the initial response
              // Promise 3 is for the initial attempt
              promises[0].then(navigatePromiseHandler).catch(promiseErrorHandler);
              promises[1].then(logEventPromiseHandler).catch(promiseErrorHandler);
              promises[2].then(logInitialAttemptPromiseHandler)

          }else{
              navigateToUrl(props.href,pageContext,componentContext,props);
          }
      })

}
