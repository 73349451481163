import { getNonInlineNuxeoImage, getNuxeoImage, getNuxeoLinkUrl } from '../utils/nuxeo.utils'


function getSize(nuxeoProperties,desktopSize,mobileSize){

    return nuxeoProperties.mobile ? mobileSize : desktopSize
}

export const NuxeoElementComponentMappings = {
    "WebCompHTML":(nuxeoProperties,site,domain,preview) => {
        return {
            "content":nuxeoProperties["html_content:body"],
        }
    },
    "WebCompCallout":(nuxeoProperties,site,domain,preview) => {
        return {
            "type":nuxeoProperties["wc_callout:type"].id,
            "background":nuxeoProperties["web_style:background"],
        }
    },
    "WebCompForm": (nuxeoProperties,site,domain,preview) => {
        return {
            "recipients":nuxeoProperties["wc_form:submitButton"].recipients,
            "fields": nuxeoProperties["wc_form:fields"],
            "title": nuxeoProperties.nuxeoTitle,
            "uid": nuxeoProperties.uid,
            "auth": nuxeoProperties.auth,
            "content": nuxeoProperties["html_content:body"],
            "submitButtonLabel":nuxeoProperties["wc_form:submitButton"] ? (nuxeoProperties["wc_form:submitButton"].label||"Submit") : "Submit",
            "resetButtonLabel":nuxeoProperties["wc_form:resetButton"] ? (nuxeoProperties["wc_form:resetButton"].label||"Reset") : "Reset"
        }

    },

    "WebCompContentBanner": (nuxeoProperties,site,domain,preview) => {
        const iconMaxWidthString= nuxeoProperties["wc_contentBanner:iconMaxWidth"]||"200px";
        const iconMaxWidth = iconMaxWidthString=="100%"?650:Number(iconMaxWidthString.replace(/[\D]/g,""));
        return {
            "iconMaxWidth":iconMaxWidthString,
            "newTab": nuxeoProperties["web_button:newTab"],
            "title": nuxeoProperties["wc_contentBanner:title"],
            "subtitle": nuxeoProperties["wc_contentBanner:subtitle"],
            "content": nuxeoProperties["wc_contentBanner:copy"],
            "height": nuxeoProperties["wc_contentBanner:height"],
            "hideOverlay": nuxeoProperties["wc_contentBanner:hideOverlayFilter"],
            "iconImage": getNuxeoImage(nuxeoProperties["wc_contentBanner:icon"],getSize(nuxeoProperties,iconMaxWidth)),
            "backgroundImage": getNonInlineNuxeoImage(nuxeoProperties["wc_contentBanner:contentImage"],getSize(nuxeoProperties,1920,450)),
            "button": (nuxeoProperties["web_button:label"] != null) ? (
                { 
                    label: nuxeoProperties["web_button:label"], 
                    requiresMemberValidation: nuxeoProperties["web_button:requireMemberValidation"], 
                    vendorId: nuxeoProperties["web_button:vendorId"], 
                    vendorName: nuxeoProperties["web_button:vendorName"]?.properties?.label,
                    href: getNuxeoLinkUrl(nuxeoProperties["web_button:doc"], nuxeoProperties["web_button:url"],nuxeoProperties.webButton,site,domain,preview) 
                }) : null
        }

    },
    "WebCompBookingWidget": (nuxeoProperties,site,domain,preview) => {
        const categories = nuxeoProperties["wc_bookingWidget:categories"]||["cars","hotels","flights","cruises"]
        const defaultActiveMap = {
            "hotels":0,
            "flights":1,
            "cars":2,
            "cruises":3
        }
        return { 
            "hideCar": categories.indexOf("cars")==-1 ,
            "hideHotel": categories.indexOf("hotels")==-1 ,
            "hideFlight": categories.indexOf("flights")==-1 ,
            "hideCruise": categories.indexOf("cruises")==-1 ,
            "defaultActive": defaultActiveMap[categories[0]]
        }

    },
    "WebCompCarouselStackedBlocks": (nuxeoProperties,site,domain,preview) => {
        return { 
            "content": nuxeoProperties["html_content:body"] ,
            "background":nuxeoProperties["web_style:background"]
        }

    },
    "WebCompHighlight": (nuxeoProperties,site,domain,preview) => {


        const webHighlight = nuxeoProperties.webHighlight || {}
        const theme = webHighlight.theme||{txtColor:"black",bgColor:"white"};

        return {
            "content": nuxeoProperties["html_content:body"],
            "items": nuxeoProperties["wc_highlight:items"],
            "textColor": theme.txtColor,
            "backgroundColor": theme.bgColor
        }

    },
    "WebCompImageContentSection": (nuxeoProperties,site,domain,preview) => {
        var label = nuxeoProperties["web_button:label"]
        return {
            "newTab": nuxeoProperties["web_button:newTab"],
            "background":nuxeoProperties["web_style:background"],
            "content": nuxeoProperties["html_content:body"],
            "mediaDetails":nuxeoProperties["webImageContentSection"]? nuxeoProperties["webImageContentSection"].mediaDetails : {},
            "button": (label == null || label.trim().length == 0) ? null : {
                doc: nuxeoProperties["web_button:doc"],
                label: nuxeoProperties["web_button:label"],
                style: nuxeoProperties["web_button:style"],
                requiresMemberValidation: nuxeoProperties["web_button:requireMemberValidation"]==true, 
                vendorId: nuxeoProperties["web_button:vendorId"], 
                url: nuxeoProperties["web_button:url"],
                vendorName: nuxeoProperties["web_button:vendorName"]?.properties?.label,
                href:getNuxeoLinkUrl(nuxeoProperties["web_button:doc"], nuxeoProperties["web_button:url"],nuxeoProperties.webButton,site,domain,preview) 
            },
            "title": nuxeoProperties["title"],
            "alternate": nuxeoProperties["wc_imgContentSection:alternate"] == true,
            "layout": nuxeoProperties["wc_imgContentSection:layout"],
            "imgRadius": nuxeoProperties["wc_imgContentSection:imageCornerRadius"],
            "imgWidth": nuxeoProperties["wc_imgContentSection:imageWidth"],
            "imgAspectRatio": nuxeoProperties["wc_imgContentSection:imageAspectRatio"],
            "backgroundImage": getNuxeoImage(nuxeoProperties["wc_imgContentSection:media"],getSize(nuxeoProperties,768,450)),
            "backgroundIds":nuxeoProperties["wc_imgContentSection:media"]
        }

    },
    "WebCompTextContentSection": (nuxeoProperties,site,domain,preview) => {
        var label = nuxeoProperties["web_button:label"]
        return { 
            "newTab": nuxeoProperties["web_button:newTab"],
            "background":nuxeoProperties["web_style:background"],
            "content": nuxeoProperties["html_content:body"],
            "button": (label == null || label.trim().length == 0) ? null : {
                doc: nuxeoProperties["web_button:doc"],
                label: nuxeoProperties["web_button:label"],
                style: nuxeoProperties["web_button:style"],
                requiresMemberValidation: nuxeoProperties["web_button:requireMemberValidation"]==true, 
                vendorId: nuxeoProperties["web_button:vendorId"], 
                url: nuxeoProperties["web_button:url"],
                vendorName: nuxeoProperties["web_button:vendorName"]?.properties?.label,
                href:getNuxeoLinkUrl(nuxeoProperties["web_button:doc"], nuxeoProperties["web_button:url"],nuxeoProperties.webButton,site,domain,preview) 
            },
        }

    },
    "WebCompMenu": (nuxeoProperties,site,domain,preview) => {

        const items = nuxeoProperties["wc_menu:items"] || [];

        const endpointMappings = (nuxeoProperties.webMenu && nuxeoProperties.webMenu.menuRefs) ? nuxeoProperties.webMenu.menuRefs :null;

        return { "items": items.map(x => { return { newTab:x.newTab, label: x.label,document:x.doc,url:x.url, href: getNuxeoLinkUrl(x.doc, x.url,endpointMappings,site,domain,preview) } }) }

    },
    "WebCompHotelCardsSection": (nuxeoProperties,site,domain,preview) => {
        
        return {
            "newTab": nuxeoProperties["web_button:newTab"],
            "background":nuxeoProperties["web_style:background"],
            "content": nuxeoProperties["html_content:body"],
            "cards": nuxeoProperties["webCardsSection"].map(x => {
                var img = x.image;
                return { 
                    href: x.href,
                    date:x.date,
                    diamondRating:x.diamondRating||0,
                    offer: x.offer||"",
                    title: unescape( x.title),
                    subtitle: x.subtitle||"",
                    shortDescription: x.shortDescription,
                    category: x.category ? {title:x.category.title||"",color:x.category.color || "#444F6B",icon:x.category.icon ||"ui-check"} : {title:"",color:"#444F6B"},
                    
                    image: img 
                }
            }),
            "title": nuxeoProperties["wc_cardsSection:title"],
            "layout": nuxeoProperties["wc_cardsSection:contentLayout"],
            "horizontal": nuxeoProperties["wc_cardsSection:cardLayout"] == "horizontal"
        }
    },
    "WebCompCardsSection": (nuxeoProperties,site,domain,preview) => {
        
        return {
            
            "newTab": nuxeoProperties["web_button:newTab"],
            "background":nuxeoProperties["web_style:background"],
            "content": nuxeoProperties["html_content:body"],
            "cards": nuxeoProperties["webCardsSection"].map(x => {
                var img = x.image;

                if (img == null || img.trim().indexOf("http") == -1) {
                    var media = x["media"];

                    // If this isn't a string, use the public Url
                    if(typeof media !== "string"){
                        media = media.publicUrl;
                    }

                    img = getNuxeoImage(media,getSize(nuxeoProperties,400,400))
                }
                var button = null;

                // Make sure we have button data
                if(x.button){

                    // Make sure we have a label and doc/url
                    if(x.button.label!=null||(x.button.doc!=null||x.button.url!=null)){
                        button = x.button
                        if(button.docEndpoint){
                            button.endpoint=button.docEndpoint
                            button.href= getNuxeoLinkUrl(x.button.doc, x.button.url,button,site,domain,preview) 
                        }else if(x.button.url){
                            // use the URL provided
                            button.href= x.button.url
                        }
                    }
                }
                return { 
                    button,
                    date:x.date,
                    offer: x.offer||"",
                    title: x.title,
                    subtitle: x.subtitle||"",
                    shortDescription: x.shortDescription,
                    category: x.category ? {title:x.category.title||"",color:x.category.color || "#444F6B",icon:x.category.icon ||"ui-check"} : {title:"",color:"#444F6B"},
                    
                    href: getNuxeoLinkUrl(x["web_button:doc"], x["web_button:url"],button,site,domain,preview) ,
                    image: img 
                }
            }),
            "title": nuxeoProperties["wc_cardsSection:title"],
            "layout": nuxeoProperties["wc_cardsSection:contentLayout"],
            "horizontal": nuxeoProperties["wc_cardsSection:cardLayout"] == "horizontal"
        }
    },

    "WebCompListSection": (nuxeoProperties,site,domain,preview) => {
        const webListSection = nuxeoProperties["webListSection"] || []
        return {
            "background":nuxeoProperties["web_style:background"],
            "content": nuxeoProperties["html_content:body"],
            "columnLayout": nuxeoProperties["wc_listSection:columnLayout"],
            "listingLayout": nuxeoProperties["wc_listSection:listingLayout"],
            "groups": webListSection.map(x => {
                return {
                    "button":x.button,
                    "title": x.title,
                    "items": (x.items||[]).map(y => {
                        var media = y["media"];
    
                        // If this isn't a string, use the public Url
                        if(typeof media !== "string"){
                            media = media.publicUrl;
                        }
                        
                        var button = null;
                        // Make sure we have button data
                        if(y.button){

                            // Make sure we have a label and doc/url
                            if(y.button.label!=null||(y.button.doc!=null||y.button.url!=null)){
                                button = y.button
                                if(button.docEndpoint){
                                    button.endpoint=button.docEndpoint
                                    button.href= getNuxeoLinkUrl(y.button.doc, y.button.url,button,site,domain,preview) 
                                }else if(y.button.url){
                                    // use the URL provided
                                    button.href= y.button.url
                                }
                            }
                        }
                                return {
                            "button":button,
                            "title": y.title,
                            "description": y.description,
                            "image": getNuxeoImage(media,100),
                            "url": y.linkUrl||""
                        }
                    })
                }
            })
        }
    },
    "WebCompAccordionSection": (nuxeoProperties,site,preview) => {
        const webAccordionSection = nuxeoProperties["webAccordionSection"] || [];
        return {
            "background":nuxeoProperties["web_style:background"],
            "title": nuxeoProperties["title"],
            "content": nuxeoProperties["html_content:body"],
            "items": webAccordionSection.map(x => { return { title: x.label, content: x.body, faq: x.faq } })
        }
    },
    "WebCompImage": (nuxeoProperties,site,preview) => {
        return {
            "background":nuxeoProperties["web_style:background"],
            ...nuxeoProperties["webImage"],
            "contentWidth":nuxeoProperties["wc_image:contentWidth"],
            "parallax":nuxeoProperties["wc_image:parallax"],
            "fullWidth":nuxeoProperties["wc_image:fullWidth"],
            "layout":nuxeoProperties["wc_image:layout"],
        }
    }
}