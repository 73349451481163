import React from 'react'
import {Input} from '@aaa/muse-ds/Input'
import {TextArea} from '@aaa/muse-ds/TextArea'
import {Select} from '@aaa/muse-ds/Select'
import {SelectOption} from '@aaa/muse-ds/SelectOption'
import {useState} from 'react'

export default function WebCompFormComponent(props){

    const {invalid} = props

    // Add an asterisk if it's required
    const label = props.label+(props.required? "*":"");

    const tryChange = (value)=>{
        props.onChange({[props.label]:value})
    }

    if(props.type=="textArea"){
        return <TextArea 
                    label={label} 
                    width={"100%"}
                    required={props.required}
                    defaultValue={props.defaultValue||""}
                    onChange={(e)=>tryChange(e.target.value)} 
                    placeholder={props.placeholder||""}/>
    }else if(props.type=="dropdown"){
        return <Select  
                    label={label} 
                    formError={invalid}
                    required={props.required}
                    defaultValue={props.defaultValue||""}
                    onChange={(e)=>tryChange(e.target.value)} 
                    placeholder={props.placeholder||""}>

            <SelectOption name="" value="">Choose a Value</SelectOption>

            {/* Create a select option for each item. */}
            {props.dropdownValues.map(x=><SelectOption name={x} value={x}>{x}</SelectOption>)}
        </Select>
    }else{
        return <Input 
                    label={label} 
                    formError={invalid}
                    required={props.required}
                    defaultValue={props.defaultValue||""}
                    onChange={(e)=>tryChange(e.target.value)} 
                    placeholder={props.placeholder||""}/>;
    }

}