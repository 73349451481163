import React from 'react'
import { Button } from "@aaa/muse-ds/Button";
import { Card } from "@aaa/muse-ds/Card";
import { Pill } from "@aaa/muse-ds/Pill";
import { SwiperWrapper  } from "@aaa/muse-ds/SwiperWrapper";
import {Container} from '@aaa/muse-ds/Container';
import ContentTitleContainerComponent from "../ContentTitleContainerComponent";
import { executeButtonAction } from "../../utils/button.utils";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import { SEOComponentContext } from "../../utils/component.utils";
import { getHeaderFromRichText } from "../../utils/text.utils";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import MemberValidationButton from "../special/MemberValidationButton";
import { MasonryGrid, CARD_SIZE} from '@aaa/muse-ds/MasonryGrid';
import { getNavigateToUrlTarget } from "../../utils/navigation.utils";

export default function WebCompCardsSection(props) {
  
    const content = props["content"] ||""
    const nonFilteredCards = props["cards"] || []
    const title = props.title || "";

    const cards = nonFilteredCards.filter(x=>{

      if(x.date==null||x.date==undefined)return true;
      if(x.date.from==0&&x.date.to==0)return true;

      const now = new Date().getTime();

      if(x.date.from>now)return false;
      if(x.date.to<now)return false;

      return true;
    })
    
    const backgroundColorValue  = props.background==null ? "#dee0e7" : props.background.color;


    const pageContext = useContext(SEOPageDataContext)||{};
    const componentContext = useContext(SEOComponentContext)||{};
    const {site,domain,pageName} = pageContext;

    const header = getHeaderFromRichText(content);
    const ConditionalWrapper = ({ condition, wrapper, children, elseWrapper }) => condition ? wrapper(children) : elseWrapper(children);
    
    return (
    <ContentTitleContainerComponent background={backgroundColorValue} title={title} content = {content}>
        <ConditionalWrapper
            condition={props.layout == "grid" && props["horizontal"]==false}
            wrapper={
                children => 
                <Container>
                <MasonryGrid>
                    
                    {children}
                    
                </MasonryGrid>
                </Container>
            }
            elseWrapper={
                children =>
                <SwiperWrapper freeMode keyboard navigation pagination swiperAuto centerAlign>
                    {children}
                </SwiperWrapper>
                
            }
        >
          
            {cards.map((x,xi) => {

              var button = x.button || {vendorName:{label:null}}

              var vendorName = null;

              if(button?.vendorName?.label&&button.vendorName.label.trim().length>0)vendorName=button.vendorName.label;

              const href = (x.button&&x.button.href) ? x.button.href : null;

              const customEventData = {
                "card_click" : {
                  destination_url:href,
                  component_buttonlink:x.button ? x.button.label : "No Label",
                  component_header:x.title,
                  component_subheader:x.subtitle||"No Subtitle",
                  component_image:x.image,
                  component_id:props.nuxeoTitle,
                  component_callout:x.offer||"No Offer",
                  vendor_name: vendorName||pageContext.vendorId||x.title||""
                }
              }
                           
              var dateText=null;
              const date = x.date;
              if(x.offer){dateText=x.offer;}
              if(date){

                // Make sure we have valid/non-zero values
                if(date.from!=null&&date.to!=null&&date.from!=0&&date.to!=0){

                  // Get the date for EST
                  const fromDate = new Date(date.from).toLocaleDateString('en-US', {timeZone: 'UTC'});
                  const toDate = new Date(date.to).toLocaleDateString('en-US', {timeZone: 'UTC'});

                  dateText = fromDate+"-"+toDate;

                }
              }
              const pill = dateText !=null ? <Pill pillPrice={dateText}/> : null;
              const hasButton = (x.button && x.button.href&&x.button.label&&x.button.label.trim().length>0);
              var target = getNavigateToUrlTarget(pageContext,componentContext);

              if(hasButton){
                if(x.button.newTab!=null&&x.button.newTab==true)target="_blank";
              }
              return <Card
              key={x.title+" card"}
              extraClass={props.layout == "grid" ? CARD_SIZE.LARGE_CARD : null}
              ariaLabel={x.title}
              categoryColor={x.category.color}
              categoryIcon={false}
              pill={pill}
              horizontalCardLeftImage={props["horizontal"]==true}
              reverseCardImage={props["horizontal"]!=true}
              price={x.offer}
              cardAction={(e)=>{

                e.preventDefault();
                e.stopPropagation();

                const label = hasButton ? x.button.label : x.title;
                const requiresMemberValidation = hasButton ? x.button.requireMemberValidation : false;
                const vendorId = hasButton ? x.button.vendorId : "";

                // If we have a  null, or empty href, prevent button execution
                if(href!=null&&href.trim().length>0){

                  executeButtonAction(e,{...props,newTab:target=="_blank",href:href,vendorId:vendorId,requiresMemberValidation:requiresMemberValidation,label:label},pageContext,componentContext,"card_click",customEventData);
              
                }
              }}


              openInNewTab ={target=="_blank"}
              detailURL={(!hasButton||!x.button.requireMemberValidation) ? href : null}
              href={(!hasButton||!x.button.requireMemberValidation) ? href : null}
              categoryTitle={x.category.title}
              description={x.shortDescription}
              imgURL={x.image}
              subtitle={x.subtitle||""}
              title={x.title}
              contentArea={
                hasButton? (
                    <><br/>
                  <MemberValidationButton
                    ariaLabel={x.button.label}
                    label={x.button.label}
                    parentName={x.nuxeoTitle}
                    newTab={target=="_blank"}
                    customEventType={"card_click"}
                    customEventData = {customEventData}
                    vendorId={x.button.vendorId} 
                    requiresMemberValidation={x.button.requireMemberValidation} 
                    href={href}
                    style="NEU_PRIMARY"
                  />
                  </>
                ) : null
              }
            />
            })}
        </ConditionalWrapper>
    </ContentTitleContainerComponent>
    )
  
}
