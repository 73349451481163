export function getNavigateToUrlTarget(pageContext,componentContext){

    try{

        var newTab=false;

        // If we are on network of savings, open in new tab
        if((pageContext?.site||"").trim().toLowerCase()=="networkofsavings"){
            newTab =true;
        }

        // Allow enabling at the page level
        if(pageContext?.tags&&pageContext?.tags.includes("links-new-tabs")){
            newTab=true;
        }

        // Allow enabling at the component level
        if(componentContext?.tags && componentContext?.tags.includes("links-new-tabs")){
            newTab=true;
        }

        if(componentContext?.newTab==true){
            newTab=true;
        }

        if(newTab){
            
            return "_blank"
        }

    }catch(e){
        console.log(e)
    }

    return "_self"
}

export function navigateToUrl(url, pageContext,componentContext,props,data){

    // If our url starts with 'javascript'
    if(url && url.toLowerCase().indexOf("javascript:")==0){

        // get the function name
        var javascriptFunction = url.substring(11)

        // If this function exists on the window
        if(window[javascriptFunction]!=null){

            // call this function with our data
            window[javascriptFunction](data)
        }

    }else{


        var target = getNavigateToUrlTarget(pageContext,componentContext)

        if(props!=null&&props.newTab!=null){
    
            target = props.newTab==true ? "_blank":target;
        }
        
        window.open(url,target);


    }

}