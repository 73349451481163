import React from 'react'
import { Section } from "@aaa/muse-ds/Section";
import { LazyImage } from '@aaa/muse-ds/LazyImage';
import { Container } from '@aaa/muse-ds/Container';
import { ContentSection } from '@aaa/muse-ds/ContentSection';
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import { getNuxeoImage} from '../../utils/nuxeo.utils'
import { SEOPageDataContext } from '../../App';
import {useContext} from 'react'

export default function WebCompImage(props) { 

    const pageContext = useContext(SEOPageDataContext);
    const layout = props.layout || "fullWidth";
    const width = (layout=="contentWidth")?{width:"980"}:{}
    const height = props[layout].height || "auto";
    const fullWidthAspectRatio = props.fullWidth.aspectRatio;
    const contentWidthAspectRatio = props.contentWidth.aspectRatio;

    const cornerRadius = layout=="contentWidth"? {roundCornerAll:props.contentWidth.cornerRadius || null}:{};
    const alt = props.caption || "";

    // Use eager loading if we are the first component
    // or the second, and the first is the menu
    var imageLoading = props.sectionIndex==0 ? "eager":"lazy";
    if(props.sectionIndex.toString()=="1"&&pageContext.menuIsFirst)imageLoading="eager";

    if(layout=="parallax"){
        return  <>
        
    <SectionHiddenIdComponent/>
    <ContentSection height={height.replace("%","vh")} parallax  parallaxImgSrc={getNuxeoImage(props.publicUrl,1920)} {...width}/>
        </>

    }else if(layout=="fullWidth"){
        return  <Section noPadding>
            
    <SectionHiddenIdComponent/>
                    <Container noPadding {...width}>
                        <LazyImage loading={imageLoading}  id={"image-component-"+props.sectionIndex} width={"100%"} aspectRatio={fullWidthAspectRatio} height={height != null && fullWidthAspectRatio == "auto" ? height.replace("%","vh") : "auto"} src={getNuxeoImage(props.publicUrl,1920)}  block alt={alt} />
                    </Container>
                </Section>
    } else {
        return  <Section noLeftPadding noRightPadding>
            
    <SectionHiddenIdComponent/>
                    <Container {...width}>
                        {/* We need to add the Title for the Image Credit - Needs to be added to the DS */}
                        <LazyImage loading={imageLoading} id={"image-component-"+props.sectionIndex} {...cornerRadius} aspectRatio={contentWidthAspectRatio} style={props.contentWidth.showShadow ? "Shadow" : {}} width={"100%"} src={getNuxeoImage(props.publicUrl,950)} alt={alt} />
                    </Container>
                </Section>
    }
}