import { getClub } from './cookie.utils';
import { navigateToUrl } from './navigation.utils';

// Logging
var AAAtag = {
  logEventAction: function (site, domain, page, eventType, event) {
    if (site.trim().toLowerCase()=="networkofsavings") return;
    if (location.href.indexOf('networkofsavings.aaa.biz') != -1) return;
    if (location.href.indexOf('localhost') != -1) return;

    if (typeof window !== 'undefined') {
      var appId = '';
      var refUrl = '';
      try {
        appId = (document.cookie.match(
          /^(?:.*;)?\s*seo_ref_url\s*=\s*([^;]+)(?:.*)?$/
        ) || [, 'SEO'])[1];
        refUrl = (document.cookie.match(
          /^(?:.*;)?\s*seo_ref_url\s*=\s*([^;]+)(?:.*)?$/
        ) || [, '.'])[1];
      } catch (e) {}
      if (appId.match(/aaa\.com/)) {
        appId = 'AAACOM';
      } else {
        appId = 'SEO';
      }
      try{
        var club = getClub();
        var attrs = {
          site,
          domain,
          page,
          action: event,
          eventAction: eventType,
          appId: appId,
          club: club,
          refUrl: refUrl,
        };
        if (window.lc_logEvent) window.lc_logEvent(attrs);
      }catch(e){
        console.log(e)
      }
    }
  },
};

export function logPageView(site, domain, page, breadcrumbs, webAnalytics,pageContext) {
  if (typeof AAAtag !== 'undefined') {
    AAAtag.logEventAction(site, domain, page, 'PAGEVIEW');
  }
  if (typeof adobeAnalytics_logEvent !== 'undefined') {
    adobeAnalytics_logEvent(adobeAnalyticsEvents.PAGEVIEW, {
      business_line: webAnalytics?.businessLine?.properties?.label || '',
      page_type: webAnalytics?.type?.properties?.label || "",
      product_category: webAnalytics?.productCategory?.properties?.label ||"",
      vendor_name: webAnalytics?.vendorName?.properties?.label || "",
      business_line_product: webAnalytics?.businessProduct?.properties?.label||""
    });
  }
}

export async function logNavigationClick(site, domain, page, action,componentId,destinationUrl) {
  return new Promise((resolve, reject) => {
    if (typeof adobeAnalytics_logEvent !== 'undefined') {
      
      const  componentIdObj = componentId? {component_id:componentId}:null
      adobeAnalytics_logEvent(adobeAnalyticsEvents.NAVIGATION_CLICK, {
        navigation_link: action,
        destination_url: destinationUrl
      },componentIdObj);
    }else{

      // Stop now if we don't have the adobe analytics method
      return resolve();
    }

    // log to Tealium
    if (typeof AAAtag !== 'undefined')
      AAAtag.logEventAction(site, domain, page, 'LINKCLCK', action);

    // Wait a tiny bit,
    setInterval(resolve, 200);
  });
}
export async function logMemberValidationPrompt(site, domain, page, eventData) {
  return new Promise((resolve, reject) => {
    if (typeof adobeAnalytics_logEvent !== 'undefined') {
      adobeAnalytics_logEvent(adobeAnalyticsEvents.MEMBER_VALIDATION_PROMPT, eventData,eventData);
    }else{

      // Stop now if we don't have the adobe analytics method
      return resolve();
    }

    // log to Tealium
    if (typeof AAAtag !== 'undefined')
      AAAtag.logEventAction(site, domain, page, 'LINKCLCK', "member_validation_prompt");

    // Wait a tiny bit,
    setInterval(resolve, 200);
  });
}
export async function logCustomEvent(site, domain, page, action,customEventType, customEventData) {
  return new Promise((resolve, reject) => {
    if (typeof adobeAnalytics_logEvent !== 'undefined') {
      adobeAnalytics_logEvent(customEventType, {},customEventData);
    }else{

      // Stop now if we don't have the adobe analytics method
      return resolve();
    }

    // log to Tealium
    if (typeof AAAtag !== 'undefined')
      AAAtag.logEventAction(site, domain, page, 'LINKCLCK', action);

    // Wait a tiny bit,
    setInterval(resolve, 200);
  });
}

export async function logLinkClick(site, domain, page, action,destinationUrl,vendorName) {
  return new Promise((resolve, reject) => {
    if (typeof adobeAnalytics_logEvent !== 'undefined') {
      adobeAnalytics_logEvent(adobeAnalyticsEvents.CTA_CLICK, {
        cta_value: action,
        destination_url: destinationUrl,
        vendor_name:vendorName
      });
    }else{

      // Stop now if we don't have the adobe analytics method
      return resolve();
    }

    // log to Tealium
    if (typeof AAAtag !== 'undefined')
      AAAtag.logEventAction(site, domain, page, 'LINKCLCK', action);

    // Wait a tiny bit,
    setInterval(resolve, 200);
  });
}

export const addLoggingToJSXHyperlinks = (
  jsxElementsArray,
  pageContext,
  componentContext
) => {
  const nuxeoTitle = componentContext
    ? componentContext.nuxeoTitle
    : pageContext
    ? pageContext.pageName
    : 'No Page Name';

  for (var i = 0; i < jsxElementsArray.length; i++) {
    const jsxElement = jsxElementsArray[i];

    if(typeof jsxElement === "string")continue;

    // If this is a hyperlink
    if (jsxElement.type == 'a') {
      // If we have a href property
      if (jsxElement.props && jsxElement.props.href) {
        const hyperlinkHref = jsxElement.props.href;
        const eventName = jsxElement.props.children;

        // TODO: revert this comment in the future
        // const target = jsxElement.props.target || "_self";

        // Create an on click event for the item
        const onClick = (e) => {

          // Prevent default behavior (actual relocation)
          e.preventDefault();
          try{e.nativeEvent.stopImmediatePropagation();}catch(ee){}
          e.stopPropagation();

          logNavigationClick(pageContext.site,pageContext.domain,pageContext.pageName,"Link Click: "+eventName.toString().trim(),nuxeoTitle,hyperlinkHref).then((x) => {
            navigateToUrl(hyperlinkHref,pageContext,componentContext);
          }).catch(e=>{
            console.error(e)
          })
        };

        // Overwrite the element with a new click event
        jsxElementsArray.splice(i, 1, {
          ...jsxElement,
          props: { ...jsxElement.props, onClick: onClick },
        });
      }
    } else if (jsxElement.props && jsxElement.props.children) {
      if (Array.isArray(jsxElement.props.children)) {
        addLoggingToJSXHyperlinks(
          jsxElement.props.children,
          pageContext,
          componentContext
        );
      }
    }
  }
};
