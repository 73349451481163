import React from 'react'
import parse from 'html-react-parser';

import {  Section} from '@aaa/muse-ds/Section';
import {  Container} from '@aaa/muse-ds/Container';
import {  RichContentWrapper} from '@aaa/muse-ds/RichContentWrapper';
import { addLoggingToJSXHyperlinks } from '../../utils/logging.utils';
import { SEOPageDataContext } from '../../App';
import {useContext} from 'react'
import { SEOComponentContext } from '../../utils/component.utils';
import SectionHiddenIdComponent from '../special/SectionHiddenIdComponent';



export default function WebCompHTML(props) {

  const pageContext = useContext(SEOPageDataContext);
  const componentContext = useContext(SEOComponentContext);
  /**
   * The wrapping span is neccessary.
   * This method won't operate correctly when content doesn't have a parent.
   * This can be the case with nuxeo content from time to time.
   */
  var jsx = parse("<div>"+props.content+"</div>");

  //removeScriptTags(jsx)
  // Add logging to hyperlinks
  addLoggingToJSXHyperlinks(jsx.props.children,pageContext,componentContext)

  return (
    <Section backgroundColor={props.background} noLeftPadding noRightPadding>
      
    <SectionHiddenIdComponent/>
    <Container width="1366">  
      <RichContentWrapper>{jsx}</RichContentWrapper>
    </Container>
    </Section>
  );
}
