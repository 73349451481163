
import parse from 'html-react-parser';

export const getFlattendTextFromRichText = (htmlString) => {

    const element = parse("<div>" + htmlString + "</div>")

    return getTextForElementOrChildren(element);
}

export const getHeaderFromRichText = (htmlString) => {

    try{

    const element = parse("<div>" + htmlString + "</div>")

    const child=getHeaderFromElementOrChildren(element)

    if(child){

        if(typeof child.value ==='string'){

            return child.value
        }else{

            return mapChildrenToText(child.value);
        }

    }
}catch(e){
    
}

    return "";
}

const mapChildrenToText= (child)=>{

    if(Array.isArray(child))return child.map(x=>mapChildrenToText(x)).join("")
    if(typeof child ==="string")return child;
    if(child.props==null)return "";
    if(child.props.children==null)return "";
    if(typeof child.props.children ==="string")return child.props.children;

    return child.props.children.map(x=>mapChildrenToText(x)).join("")
}



const getHeaderFromElementOrChildren = (jsxElement) => {

    if(typeof jsxElement ==='string')return null;

    // If this is a heading
    if (jsxElement.type == 'h2') return {tag:jsxElement.type,value:jsxElement.props.children};
    if (jsxElement.type == 'h3') return  {tag:jsxElement.type,value:jsxElement.props.children};
    if (jsxElement.type == 'h4') return  {tag:jsxElement.type,value:jsxElement.props.children};
    if (jsxElement.type == 'h5') return  {tag:jsxElement.type,value:jsxElement.props.children};
    if (jsxElement.type == 'h6') return  {tag:jsxElement.type,value:jsxElement.props.children};;

    // For span's, p tags, or divs we'll check our children
    if (jsxElement.type == "span" || jsxElement.type == "p" || jsxElement.type == "div") {

        // If this element has children
        if (jsxElement.props.children) {

            const children = Array.isArray(jsxElement.props.children) ? jsxElement.props.children : [jsxElement.props.children]

            const values = [];

            for (var i = 0; i < children.length; i++) {

                // Get the header from this element or it's children
                const child = getHeaderFromElementOrChildren(children[i])

                // Return the value if present
                if (child) values.push(child);
            }

            values.sort((a,b)=>{

                if(a.tag>b.tag)return 1;
                if(a.tag<b.tag)return -1;
                return 0;
            })

            if(values.length>0)return values[0]
        }
    }

    return null;
}


const getTextForElementOrChildren = (jsxElement) => {

    if(typeof jsxElement ==='string')return jsxElement;

    // For span's, p tags, or divs we'll check our children
    if (jsxElement.type == "span" || jsxElement.type == "p" || jsxElement.type == "div") {

        // If this element has children
        if (jsxElement.props.children) {

            const children = Array.isArray(jsxElement.props.children) ? jsxElement.props.children : [jsxElement.props.children]

            const values = [];

            for (var i = 0; i < children.length; i++) {

                // Get the header from this element or it's children
                const child = getTextForElementOrChildren(children[i])

                // Return the value if present
                if (child) values.push(child);
            }

            if(values.length>0)return values.join("")
        }
    }

    return null;
}