import React from 'react'
import { Card } from "@aaa/muse-ds/Card";
import { SwiperWrapper  } from "@aaa/muse-ds/SwiperWrapper";
import {Container} from '@aaa/muse-ds/Container';
import ContentTitleContainerComponent from "../ContentTitleContainerComponent";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import { SEOComponentContext } from "../../utils/component.utils";
import { MasonryGrid, CARD_SIZE} from '@aaa/muse-ds/MasonryGrid';
import { logCustomEvent } from "../../utils/logging.utils";

export default function WebCompHotelCardsSection(props) {
    
    const diamondRatings = ["","diamond-approved","diamond-approved","diamond-three","diamond-four","diamond-five"];
  
    const content = props["content"] ||""
    const cards = props["cards"] || []
    const title = props.title || "";
    
    const backgroundColorValue  = props.background==null ? "#dee0e7" : props.background.color;


    const pageContext = useContext(SEOPageDataContext)||{};
    const {site,domain,pageName} = pageContext;

    const ConditionalWrapper = ({ condition, wrapper, children, elseWrapper }) => condition ? wrapper(children) : elseWrapper(children);
    
    return (
    <ContentTitleContainerComponent background={backgroundColorValue} title={title} content = {content}>
        <ConditionalWrapper
            condition={props.layout == "grid" && props["horizontal"]==false}
            wrapper={
                children => 
                <Container>
                <MasonryGrid>
                    
                    {children}
                    
                </MasonryGrid>
                </Container>
            }
            elseWrapper={
                children =>
                <SwiperWrapper freeMode keyboard navigation pagination swiperAuto centerAlign>
                    {children}
                </SwiperWrapper>
                
            }
        >
          
            {cards.map((x,xi) => {
              const customEventData = {
                "card_click" : {
                  component_buttonlink:x.button ? x.button.label : "No Label",
                  component_header:x.title,
                  component_subheader:x.subtitle||"No Subtitle",
                  component_image:x.image,
                  component_id:props.nuxeoTitle,
                  component_callout:x.offer||"No Offer",
                  vendor_name: pageContext.vendorId||x.title||""
                }
              };
              const defaultImageNUmber = Math.floor(Math.random()*5)+1;
              return <Card
              key={x.title+" card"}
              extraClass={props.layout == "grid" ? CARD_SIZE.LARGE_CARD : null}
              ariaLabel={x.title}
              inspectedClean={x.diamondRating ? true : false}
              categoryColor={"#5065ab"}
              iconBGcolor={"#5065ab"}
              categoryIcon={true}
              iconName="category--lodgings"
              cardStyleMedium={true}
              cardType="hotel"
              card={{state : "CARD_HOTEL"}}
              cardAction={(e)=>{
                e.preventDefault();
                e.stopPropagation();

                logCustomEvent(site,domain,pageName,x.title,"card_click",customEventData)
                    .then(y=>{
                        window.open(x.href,"_blank")
                    })
              }}
              defaultImg={`https://aaa.com/AAA/Common/travelgateway/images/category/ACCOMMODATION-${defaultImageNUmber}.jpg`}
              diamondAward={diamondRatings[x.diamondRating]}
              horizontalCardLeftImage={props["horizontal"]==true}
              reverseCardImage={props["horizontal"]!=true}
              categoryIconType="hotel"
              categoryTitle="HOTEL"
              openInNewTab ={true}
              detailURL={x.href}
              href={x.href}
              imgURL={x.image}
            //   subtitle={x.subtitle||""}
              title={x.title}
            />

            })}
        </ConditionalWrapper>
    </ContentTitleContainerComponent>
    )
  
}
