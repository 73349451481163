import React from 'react'

import ErrorBoundary from "../components/ErrorBoundary";
import NuxeoElementComponent from "../components/NuxeoElementComponent";
import { getTDRHotels } from "../services/tdr.service";
import { getNonInlineNuxeoImage, getNuxeoImage } from "./nuxeo.utils";
import jwt from 'jsonwebtoken'
import { JWT_SIGNING_SECRET } from '../config/environment.config';
import { logForSession } from '../utils/session.utils';


export const flattenComponentData = (x) => {
  return {
    "uid": x.uid,
    "type": x.type,
    "nuxeoTitle": x.title,
    ...(x.properties),
    ...(x.contextParameters)
  }
}

export const getBannerComponentImage = (pageData,size) => {

  const { components } = pageData;

  if (components == null) return null;

  const bannerComponent = components.find(x => x.type == "WebCompContentBanner");

  if (bannerComponent == null) return null;

  const id = bannerComponent['wc_contentBanner:contentImage']

  if (id == null) return null;
  if (id.trim().length == 0) return null;

  return getNonInlineNuxeoImage(id, size||(pageData.mobile? 450 :1920))
}

export const mapSpecialComponent = async (req, documentId,site,preview,nuxeoName, componentData, pageId, sharedComponents) => {

  // For these, we need to get the TDR data for each item
  if (componentData.type == "WebCompHotelCardsSection") {

    const ids = componentData["wc_hotelCardsSection:hotelIds"];

    try {

      // Get our hotel data from TDR 
      var hotelsData = await getTDRHotels(ids)

      const data = { ...componentData, "webCardsSection": hotelsData }

      // Map the data to a "WebCompCardsSection"
      return data

    } catch (e) {
      logForSession(req, e)

      return { "type": "WebCompTxtContentSection", "content": "Hotels could not be retried for:" + ids }
    }

    // if this is a shared component, get the shared component
  } else if (componentData.type == "WebCompShared") {

    const sharedComponent = sharedComponents.find(sharedComponent => sharedComponent.uid == componentData["wc_shared:component"])

    // Otherwise, find the item from the shared components list with the same uid
    return sharedComponent
  } else if (componentData.type == "WebCompForm") {

    const data = {
      pageId: pageId,
      formId: componentData.uid,
      documentId,
      site,
      preview,
      nuxeoName
    };

    const auth = jwt.sign(data, JWT_SIGNING_SECRET)

    return { ...componentData, auth: auth };
  }

  return componentData



}

export const SEOComponentContext = React.createContext()

export const getWrapperElement = (category, id, componentData, i, key) => {
  const tags = (componentData["nxtag:tags"]||[]).map(x=>x.label)
  return <ErrorBoundary key={key}>

    {/* Create a provider so we can access component data */}
    <SEOComponentContext.Provider value={{...componentData,tags,sectionIndex:i,type:componentData.type}}>
      <NuxeoElementComponent sectionIndex={i} {...componentData} />
    </SEOComponentContext.Provider>
  </ErrorBoundary>
}