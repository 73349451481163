import React from 'react'
import { Button } from "@aaa/muse-ds/Button"
import { Image } from "@aaa/muse-ds/Image"
import { Main } from "@aaa/muse-ds/Main"
import { PageWrapper } from "@aaa/muse-ds/PageWrapper"
import { Section } from "@aaa/muse-ds/Section"
import { Span } from "@aaa/muse-ds/Span"
import { SubMenu } from "@aaa/muse-ds/SubMenu"
import { SEOPageDataContext } from "../App"

export default function ErrorDisplay(props){

        return <SEOPageDataContext.Consumer>
            {(context)=>{

                return   <PageWrapper>
                <SubMenu
                  subMenuItems={[
                    {
                      ariaLabel: 'Discounts',
                      href: '#',
                      iconName: 'budget',
                      id: '0',
                      label: 'Discounts'
                    },
                    {
                      ariaLabel: 'Automotive',
                      href: '#',
                      iconName: 'transportation--car',
                      id: '1',
                      label: 'Automotive'
                    },
                    {
                      ariaLabel: 'Dining',
                      href: '#',
                      iconName: 'category--restaurants',
                      id: '2',
                      label: 'Dining'
                    },
                    {
                      ariaLabel: 'Entertainment',
                      href: '#',
                      iconName: 'category--things-to-do',
                      id: '3',
                      label: 'Entertainment'
                    },
                    {
                      ariaLabel: 'Home & Business',
                      href: '#',
                      iconName: 'home',
                      id: '4',
                      label: 'Home & Business'
                    },
                    {
                      ariaLabel: 'Personal Service',
                      href: '#',
                      iconName: 'ui-profile',
                      id: '5',
                      label: 'Personal Service'
                    },
                    {
                      ariaLabel: 'Shopping',
                      href: '#',
                      iconName: 'amenities--business',
                      id: '6',
                      label: 'Shopping'
                    }
                  ]}
                 />
                <Main width="1920">
                  <Section
                    backgroundColor="#fff"
                    extraClass="kSkKQ4ZeWTkGGU-WMMThOA=="
                    style="Shadow"
                    width="980"
                  >
                    <Image
                      extraClass="Rw6RZJC5Yf-z4kfWngj+Tg=="
                      src="https://csp.aaa.com/nuxeo/site/public/transform/default/cc3cb105-a79a-47a8-907d-d9044cfe772d?width=500&format=png"
                    />
                    <br />
                    <br />
                    <Span
                      bold
                      centerText
                      extraClass="FrbXsO0AL8mgI3QX96o07w=="
                      fontSize="BASE"
                    >
                      {props.errorCode||404}
                    </Span>
                    <br />
                    <Span
                      bold
                      centerText
                      extraClass="tZ6FxocZ44wc5Z491NaGYA=="
                      fontSize="XL"
                      serif
                    >
                      {props.errorMessage}
                    </Span>
                    <br />
                    <Button
                      ariaLabel=""
                      href={`https://${context.domain||"aaa.com"}`}
                      icon="ICON_LEFT"
                      iconName="ui-arrow-long--left"
                      label={`Back to ${context.domain||"aaa.com"}`}
                      style="NEU_SECONDARY"
                    />
                  </Section>
                </Main>
              </PageWrapper>
            }}
       
      </SEOPageDataContext.Consumer>
}