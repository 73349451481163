import React from 'react'
import { getHeaderFromRichText } from '../../utils/text.utils';
import MemberValidationButton from '../special/MemberValidationButton';
import SectionHiddenIdComponent from '../special/SectionHiddenIdComponent';
import {RichContentWrapper} from '@aaa/muse-ds/RichContentWrapper';
import {Section} from '@aaa/muse-ds/Section';
import {Container} from '@aaa/muse-ds/Container';
import NuxeoRichTextWrapperComponent from '../NuxeoRichTextWrapperComponent';

export default function WebCompTextContentSection(props) {
    const { content } = props;

    const background = props['background'] || {color:"#dee0e7"};

    const header = getHeaderFromRichText(content);

    const customEventData =  {
      "section_container_click": {
        "component_buttonlink":props.button ? props.button.label : "",
        "component_header":header,
        "component_id":props.nuxeoTitle,
        "destination_url":props?.buttton?.href,
        "vendor_name":props?.button?.vendorName?.label
      },
    }
  

    const optionalButtonContent = props.button ? (
      <MemberValidationButton
        ariaLabel={props.button.label}
        label={props.button.label}
        parentName={props.nuxeoTitle}
        customEventType={"section_container_click"}
        customEventData = {customEventData}
        vendorId={props.button.vendorId} 
        newTab={props.newTab}
        requiresMemberValidation={props.button.requiresMemberValidation} 
        href={props.button.href}
        style="NEU_PRIMARY"
      />
    ) : null;
  

    return <Section backgroundColor={background.color} noLeftPadding noRightPadding>
        <Container width="980">
            <RichContentWrapper>
                <NuxeoRichTextWrapperComponent content={props.content} />
                {optionalButtonContent}
                <SectionHiddenIdComponent/>
            </RichContentWrapper>
        </Container>
    </Section>
}
