import React from 'react'
import VisitAAACallout from "../callouts/VisitAAACallout";

export default function WebCompCallout(props) {

    const {type}= props;

    switch(type){
        default: return <VisitAAACallout {...props} />
    }

}