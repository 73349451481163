
export function getClub() {
	var clubCode = ["", ""];

	var zip = getCookie("zipcode");
	if (zip != "") {
		var parsed = zip.split("|");
		clubCode = [parsed[0], parsed[2]];
	}

	var club = "";
	if (clubCode[1] === undefined) {
		club = "";
	} else {
		club = ("000" + clubCode[1]).slice(-3);
	}
	return club;
}

export function getCookie(cname) {


	var name = cname + "=";
	var ca = [];
	
	try{ca = document.cookie.split(";");}catch(e){}
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}