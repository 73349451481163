import React, { useContext } from "react";
import { NuxeoElementComponentMappings } from "../config/nuxeo-component.config";

import WebCompTextContentSection from "./muse-wrappers/WebCompTextContentSection"
import WebCompCarouselStackedBlocks from "./muse-wrappers/WebCompCarouselStackedBlocks";
import WebCompContentBanner from "./muse-wrappers/WebCompContentBanner";
import WebCompHighlight from "./muse-wrappers/WebCompHighlight";
import WebCompImageContentSection from "./muse-wrappers/WebCompImgContentSection";
import WebCompMenu from "./muse-wrappers/WebCompMenu";
import WebCompCardsSection from "./muse-wrappers/WebCompCardsSection";
import WebCompListSection from "./muse-wrappers/WebCompListSection";
import WebCompAccordionSection from "./muse-wrappers/WebCompAccordionSection";
import WebCompBookingWidget from "./muse-wrappers/WebCompBookingWidget";
import WebCompForm from "./muse-wrappers/WebCompForm";
import WebCompHTML from "./muse-wrappers/WebCompHTML";
import WebCompImage from "./muse-wrappers/WebCompImage";
import WebCompCallout from "./muse-wrappers/WebCompCallout";
import WebCompHotelCardsSection from "./muse-wrappers/WebCompHotelCardsSection";

export default function NuxeoElementComponent(props){

    const type = props.type || "invalid"

    const mapNuxeoPropertiesToReact = NuxeoElementComponentMappings[type];
    
    if(mapNuxeoPropertiesToReact==null){
        console.error(`No mapping for type: ${type}`)
        return null
    }

    var mappedNuxeoProperties = null
    var site=props.production ? null : props.site;
    var domain=props.production ? props.domain : null;
    var preview=props.preview || false;

    try{

        /**
         * We'll pass props directly, and the mapped nuxeo properties.
         */
        mappedNuxeoProperties = mapNuxeoPropertiesToReact(props,site,domain,preview);
        mappedNuxeoProperties.type=props.type
        Object.keys(props).forEach(prop=>{
            mappedNuxeoProperties[prop]= props[prop]
        })
        mappedNuxeoProperties.nuxeoTitle = props.nuxeoTitle

    }catch(e){
        console.error(e)

        console.log("An error occured while mapping cms properties for type: "+props.type)
    }

    try{

        switch(type){
            case "WebCompTextContentSection": return <WebCompTextContentSection {...mappedNuxeoProperties}/>;
            case "WebCompCarouselStackedBlocks": return <WebCompCarouselStackedBlocks {...mappedNuxeoProperties}/>;
            case "WebCompContentBanner": return <WebCompContentBanner {...mappedNuxeoProperties}/>;
            case "WebCompHighlight": return <WebCompHighlight {...mappedNuxeoProperties}/>;
            case "WebCompImageContentSection": return <WebCompImageContentSection {...mappedNuxeoProperties}/>;
            case "WebCompMenu": return <WebCompMenu {...mappedNuxeoProperties}/>;
            case "WebCompCardsSection": return <WebCompCardsSection {...mappedNuxeoProperties}/>;
            case "WebCompHotelCardsSection": return <WebCompHotelCardsSection {...mappedNuxeoProperties}/>;
            case "WebCompListSection": return <WebCompListSection {...mappedNuxeoProperties}/>;
            case "WebCompAccordionSection": return <WebCompAccordionSection {...mappedNuxeoProperties}/>;
            case "WebCompBookingWidget": return <WebCompBookingWidget {...mappedNuxeoProperties}/>;
            case "WebCompForm": return <WebCompForm {...mappedNuxeoProperties}/>;
            case "WebCompHTML": return <WebCompHTML {...mappedNuxeoProperties}/>;
            case "WebCompImage": return <WebCompImage {...mappedNuxeoProperties}/>;
            case "WebCompCallout": return <WebCompCallout {...mappedNuxeoProperties}/>;
            default:null;
        }

    
    }catch(e){

        console.log("An error occured while rendering type: "+props.type)
        
    }

    return null;


}