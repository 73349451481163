import { Container } from '@aaa/muse-ds/Container'
import { Image } from "@aaa/muse-ds/Image";
import { Header2 } from "@aaa/muse-ds/Header2";
import { Spacing } from "@aaa/muse-ds/Spacing";
import { ButtonLink } from "@aaa/muse-ds/ButtonLink";
import { ButtonGroup } from "@aaa/muse-ds/ButtonGroup";
import { Paragraph } from "@aaa/muse-ds/Paragraph";
import { GridColumnsContainer } from "@aaa/muse-ds/GridColumnsContainer";
import { BumpSection } from "@aaa/muse-ds/BumpSection";
import SectionHiddenIdComponent from '../special/SectionHiddenIdComponent';

export default function VisitAAACallout(props) {

    return <BumpSection
    backgroundColor="#292f40"
    iconColor="#ffffff"
    iconName="aaa-travel-icon-one-color"
    sectionBackgroundColor={props.background.color || "transparent"}
    paddingBottom
  >
    
    <SectionHiddenIdComponent/>
    <GridColumnsContainer
      alignVertical="center"
      columnS="6"
      gap="largeGap"
      noPadding
      width="980"
    >
      <Container noPadding>
        <Image
          aspectRatio="1 / 1"
          block
          roundCornerAll="radiusL"
          src="https://csp.aaa.com/nuxeo/site/public/transform/default/54422cb5-9d69-4b71-ac06-45ef33d879d3?width=800&height=600&format=jpg&crop=800,600,0,0"
          style="Shadow"
          width="100%"
        />
      </Container>
      <Container noPadding>
        <Header2
          bold
          color="#ffffff"
          serif
          spacing
        >
          Call Or Visit A
          <br />
          AAA Travel Agent Today!
        </Header2>
        <ButtonLink
          color="RED"
          href="#"
          icon="ICON_RIGHT"
          iconName="ui-arrow-long--right"
          label="Find An Office"
        />
        <Spacing
          spacingBottom="spacingBase"
          spacingTop="spacingBase"
        >
          <hr />
        </Spacing>
        <Paragraph
          color="#ffffff"
          fontSize="S"
          spacing
        >
          As a AAA member, you'll save even more. Your AAA Travel Agent will check to see if AAA Vacations is combinable with other AAA member benefits, plus cruise and tour past passenger discounts and benefits.
        </Paragraph>
        <ButtonGroup>
          <ButtonLink
            color="WHITE"
            href="#"
            label="Not A Member?"
            style="PLAIN"
          />
          <ButtonLink
            color="RED"
            href="#"
            label="Join Now"
          />
        </ButtonGroup>
      </Container>
    </GridColumnsContainer>
  </BumpSection>
}