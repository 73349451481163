import React from 'react'
import parse from 'html-react-parser';
import { addLoggingToJSXHyperlinks } from '../utils/logging.utils';
import { useContext } from 'react';
import { SEOPageDataContext } from '../App';
import { SEOComponentContext } from '../utils/component.utils';
import { Paragraph } from '@aaa/muse-ds/Paragraph';


export default function NuxeoRichTextWrapperComponent(props){

    if(props.content==null)return null;
    if(props.content.trim().length==0)return null;

    const pageContext = useContext(SEOPageDataContext);
    const componentContext  = useContext(SEOComponentContext)

    /**
     * The wrapping span is neccessary.
     * This method won't operate correctly when content doesn't have a parent.
     * This can be the case with nuxeo content from time to time.
     */
    var jsx = parse("<div>"+props.content+"</div>");

    addLoggingToJSXHyperlinks(jsx.props.children,pageContext,componentContext)

    // Wrap in a paragrph tag for proper formatting from the DS
    return <Paragraph>{jsx.props.children}</Paragraph>
}