import React from 'react'
import { ButtonLink } from "@aaa/muse-ds/ButtonLink";
import { Button} from "@aaa/muse-ds/Button";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import { SEOComponentContext } from "../../utils/component.utils";
import { executeButtonAction } from "../../utils/button.utils";
import { getNavigateToUrlTarget } from "../../utils/navigation.utils";

export default function MemberValidationButton(props){

    const pageContext = useContext(SEOPageDataContext);
    const componentContext = useContext(SEOComponentContext);

    
    // If member validation is required
    // or if we're executing javascript
    if((props.requiresMemberValidation && props.vendorId)||props.href.toLowerCase().indexOf("javascript:")==0){

    // Use Buttonso the URL is not visible
    return <Button 
                style="BASE" 

                // We pass this for SEO reasons
                // 'preventDefault' will trigger this from naturally executing in the 'executeButtonAction' function.
                action={(e)=>executeButtonAction(e,props,pageContext,componentContext,props.customEventType,props.customEventData)} 
                label={props.label} />
    }

    var target = getNavigateToUrlTarget(pageContext,componentContext);

    if(props.newTab!=null&&props.newTab==true){
        target="_blank"
    }

    // Use ButtonLink so the URL can be populated in the href, and more visible to SEO
    return <ButtonLink 
                style="BASE" 
                target={target}

                // We pass this for SEO reasons
                // 'preventDefault' will trigger this from naturally executing in the 'executeButtonAction' function.
                href={props.href} 
                ariaLabel={props.label} 
                action={(e)=>executeButtonAction(e,props,pageContext,componentContext,props.customEventType,props.customEventData)} 
                label={props.label} />
}


