import React from 'react'
import {Container} from '@aaa/muse-ds/Container';
import {Header2} from '@aaa/muse-ds/Header2';
import {RichContentWrapper} from '@aaa/muse-ds/RichContentWrapper';
import {Section} from '@aaa/muse-ds/Section';
import parse from 'html-react-parser';

import NuxeoRichTextWrapperComponent from './NuxeoRichTextWrapperComponent';

export default function ContentTitleContainerComponent(props) {
  const hasTitle = props.title;
  const hasContent = props.content;
  const showTop = hasTitle || hasContent;

  /**
   * The wrapping span is neccessary.
   * This method won't operate correctly when content doesn't have a parent.
   * This can be the case with nuxeo content from time to time.
   */
  var titleJSX = parse("<span>"+props.title+"</span>");

  return (
    <Section backgroundColor={props.background} noLeftPadding noRightPadding>
      {/* Check if we should show the top */}
      {showTop ? (
        <>
          <Container width="980">
            <RichContentWrapper>
              {hasTitle ? (
                <Header2 serif bold centerText>
                  {titleJSX.props.children}
                </Header2>
              ) : null}
              <NuxeoRichTextWrapperComponent content={props.content} />
            </RichContentWrapper>
          </Container>
          <br />
        </>
      ) : null}

      {/* Show the props children, iff we have it */}
      {props.children ? (
        <Container noPadding>{props.children}</Container>
      ) : null}
    </Section>
  );
}
