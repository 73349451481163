import React from 'react'
import { Breadcrumbs } from "@aaa/muse-ds/Breadcrumbs";
import { logNavigationClick } from "../../utils/logging.utils";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import { navigateToUrl } from "../../utils/navigation.utils";
import { SEOComponentContext } from "../../utils/component.utils";

export default function MuseBreacrumbsComponent(props){
  
  const context = useContext(SEOPageDataContext)
  const componentContext = useContext(SEOComponentContext)

    var breadcrumbsList = [];

    if(props.breadcrumbs && props.breadcrumbs.length>0){

        var breadcrumbsList = [];
    
        // Start with just the domain, we'll add segments as we go
        var path = `https://${props.domain}`.trim();

    
        // For each breadcrumb item
        for(var i=0;i<props.breadcrumbs.length;i++){

          var endpointEntry =  props.endpoint[i];
          
          
          if(endpointEntry){
            endpointEntry = endpointEntry.trim();
            if(endpointEntry.toLowerCase()=="main")continue;
    
            if(!path.endsWith("/"))path+="/"
            path +=endpointEntry;
          }

          const optionalSiteParameter = context.query.site ? ("?site="+context.query.site) : "";
          const locationHrefPath = path+optionalSiteParameter;
          const eventName = "Breadcrumbs - Click "+props.breadcrumbs[i].trim()

          const onClick = (e)=>{
            // Prevent default, to delay the navigation
            // We'll manually navigate after the event
            // has been logged.
            e.preventDefault();
            try{e.nativeEvent.stopImmediatePropagation();}catch(ee){}
            e.stopPropagation();
            
            logNavigationClick(context.site,context.domain,"Nav Bar: "+context.page,eventName,null,locationHrefPath)
              .then(res=>{

                // Go to the target destination
                navigateToUrl(locationHrefPath,context,componentContext);
              })
          };
    
          breadcrumbsList.push({
            href:locationHrefPath,
            label:props.breadcrumbs[i],
            ariaLabel:props.breadcrumbs[i],
            onClick
          })
        }
    
          
      }

      if(breadcrumbsList.length==0)return null;
      

    return  <Breadcrumbs ariaLabel="Breadcrumbs" breadcrumbsItems={breadcrumbsList} backgroundColor="#ffffff"/>
}
