import React from 'react'
import {BookingWidget} from'@aaa/muse-ds/BookingWidget'
import {Section} from'@aaa/muse-ds/Section'
import { logCustomEvent, logNavigationClick } from '../../utils/logging.utils';
import { useContext } from 'react';
import { SEOPageDataContext } from "../../App";

import './WebCompBookingWidget.css'

export default function WebCompBookingWidget(props){
    const pageContextData = useContext(SEOPageDataContext)||{};
    const {site,domain,query} = pageContextData;
    const {pageName,vendorId} = props

    const onSearch = (e,a)=>{

        var searchId = "hotelFromLocationInput";

        switch(a.toLowerCase()){
            case "hotels":searchId = "hotelFromLocationInput";break;
            case "flights":searchId = "flightToLocation";break;
            case "cars":searchId = "carFromInputLocation";break;
            case "cruises":searchId = "anyDestinationSelect";break;
        }

        var element = document.getElementById(searchId);

        if(element){
            

            return logCustomEvent(site,domain,pageName,"","travel_widget_search",{
                
                travel_widget_search: {
                    component_buttonlink:"Search "+a,
                    location_name:element.value,
                    component_id:props.nuxeoTitle
                }
            });
        }
    }

    // Get the 'PC' or 'promoCode' parameter from the query
    const carPromoCode = ((query||{}).PC)||((query||{}).promoCode)||"";

    var chainCode=null;
    
    const chainCodeMapping={
        "accor":"rtr",
        "hilton":"eh",
        "carlson":"cw",
        "rezidor":"cw",
        "hyatt":"hyr",
        "exclusive world":"ew",
        "langham":"lor",
        "preferred":"pv",
        "nh hotel":"nsr",
        "marriott":"em",
        "extended":"ea",
        "western":"bwr",
        "starwood":"sw",
        "ihg":"6C",
        "intercontinental":"6C",
        "wyndham":"tr",
        "red lion":"rlr",
        "mgm":"mvr",
        "choice hotels":"AAACHS",
        "omni":"om"

    }
    

    var mappingKey = null;
    
    try{
        // If we have nuxeo tags
        if(props && props[`nxtag:tags`]){
            var tag = props[`nxtag:tags`].map(x=>x.label.toLowerCase()).find(x=>x.startsWith("chaincode-"))

            // If we have a tag starting with chaincode-
            // Use the portion after that
            if(tag){
                chainCode=tag.replace("chaincode-","");
            }
        }
    }catch(e){}

    if(!chainCode){
        try{
            if(pageName)mappingKey = Object.keys(chainCodeMapping).find(x=>pageName.toLowerCase().includes(x));
        }catch(e){}
        try{
            // If we don't have a mapping key
            // Try using vendor id
            if(!mappingKey && vendorId)mappingKey = Object.keys(chainCodeMapping).find(x=>vendorId.toLowerCase().includes(x));
        }catch(e){}

        if(mappingKey){

            chainCode=chainCodeMapping[mappingKey];
        }else{
            chainCode="";
        }
    }

    return (
        <Section backgroundColor="#ffffff">
            <div className="border-box-sizing">
            
    <input type="hidden" id={`Section ${props.sectionIndex} - ${props.nuxeoTitle}`} value={""}/>
            <BookingWidget 
                preFillCarInputPromoNumber={carPromoCode}
                tabOnClick={(e,b)=>{
                if(e.type=="click"){
                    logNavigationClick(site,domain,pageName,`Tab: ${b} Search`,props.nuxeoTitle)
                }
            }} 
            clubCode={pageContextData.clubCode}
            chainCode={chainCode}
            flightSearchAction={(e)=>{return onSearch(e,"Flights")}} 
            carSearchAction={(e)=>{return onSearch(e,"Cars")}} 
            cruiseSearchAction={(e)=>{return onSearch(e,"Cruises")}} 
            hotelSearchAction={(e)=>{return onSearch(e,"Hotels")}} 
            {...props}></BookingWidget>
            </div>
        </Section>
    );
}