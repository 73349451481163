import React from 'react'
import { SubMenu } from "@aaa/muse-ds/SubMenu";
import { logNavigationClick } from "../../utils/logging.utils";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import { SEOComponentContext } from "../../utils/component.utils";
import { navigateToUrl } from "../../utils/navigation.utils";

export default function WebCompMenu(props){
  
    const context = useContext(SEOPageDataContext) || {uid: ""}
    const componentContext = useContext(SEOComponentContext) || {}

    const mappedItems = (props.items||[]).map((x,i)=>{

        const locationHrefPath = x.href;
        const  eventName = x.label

        return {...x,isActive:x.document==context.uid,onClick:(e)=>{

            // Prevent default, to delay the navigation
            // We'll manually navigate after the event
            // has been logged.
            e.preventDefault();
            try{e.nativeEvent.stopImmediatePropagation();}catch(ee){}
            e.stopPropagation();


            // Log a link click
            logNavigationClick(context.site,context.domain,context.page,"Nav Bar: "+eventName,props.nuxeoTitle)
                .then(res=>{
                    navigateToUrl(locationHrefPath,context,componentContext,x);
                })
        }}
    })

    return  <div data-testid="web-comp-menu">
    <SectionHiddenIdComponent/>
        <SubMenu subMenuItems={mappedItems}  />
    </div>
}