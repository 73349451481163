import './App.css';

import React, { useEffect, useState } from 'react';
import ErrorPage from './components/ErrorDisplay';
import { useParams } from 'react-router-dom';
import MuseBreacrumbsComponent from './components/muse-wrappers/MuseBreadcrumbsComponent';
import { getWrapperElement } from './utils/component.utils';
import { logPageView } from './utils/logging.utils';
import { PageWrapper } from '@aaa/muse-ds/PageWrapper';

export const SEOPageDataContext = React.createContext();

export default function App(props) {

  const pageDataDecoded = typeof window !== 'undefined' ? (window.PAGE_DATA ? decodeURIComponent(window.PAGE_DATA): "{}") : "{}";
  const winData = typeof window !== 'undefined' ? JSON.parse(pageDataDecoded) : {};

  // Get the props from the window as a backup/default
  props = { ...winData, ...props };

  const clubCode = props.clubCode || '014'
  const error = props.error || null;
  const ets = props.ets || "";
  const AAA_TOMCAT_HOST = props.AAA_TOMCAT_HOST || "mestag.national.aaa.com";
  const preview = props.preview || false;
  const production = props.production || false;
  const components = props.components || [];
  const domain = props.domain || [];
  const breadcrumbs = props.breadcrumbs || [];
  const tags = props.tags || [];
  const endpoint = props.endpoint || "";
  const uid = props.uid || "";
  const query = props.query || {};
  const site = props.site || "";
  const mobile = props.mobile || false;
  const webAnalytics = props.webAnalytics || {};
  const menuIsFirst = (components && components.length>0) ? components[0].type=="WebCompMenu" : false;

  const businessLineProduct =  (webAnalytics.businessProduct ||{properties:{label:""}}).properties.label;
  const businessLine = (webAnalytics.businessLine ||{properties:{label:""}}).properties.label;
  const productCategory = (webAnalytics.productCategory ||{properties:{label:""}}).properties.label;
  const vendorId = ((webAnalytics.vendorName || {"properties":{"label":""}}).properties || {"label":""}).label;
  
  const pageName = props.pageName || (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : null) || 'AAA.com SEO Page';

  const {category, id} = useParams();
  
  const [contextState,setContextState]= useState({vendorName:vendorId,menuIsFirst,clubCode,ets,query,tags,AAA_TOMCAT_HOST,businessLine,businessLineProduct,productCategory,site,vendorId,production,preview,pageName,uid,breadcrumbs,domain,components,error,alternate:false});

  const mappedComponents = (components||[]).map((x,xi)=>getWrapperElement(category,id,{...x,site,production,pageName,vendorId,domain,preview,mobile},xi,"nuxeo-page-component-"+category+"-"+id+"-"+xi));

  // If the first component is a menu
  // Insert breacrumbs afterwards
  // Otherwise, insert breacrumbs at the start
  const insertIndex =
    components.length > 0 ? (components[0].type == 'WebCompMenu' ? 1 : 0) : 0;

  mappedComponents.splice(
    insertIndex,
    0,
    <MuseBreacrumbsComponent
    endpoint={endpoint||[]}
      breadcrumbs={breadcrumbs || []}
      domain={domain || ''}
    />
  );

  useEffect(() => {
    // Log our page view
    logPageView(site, domain, pageName, breadcrumbs, webAnalytics,contextState);
  }, []);

  var styleObject = null;

  // If we have a background color specified
  if(props.background && props.background.color){

    // Set the background color
    styleObject = {backgroundColor:props.background.color}
  }

  // Map each component to a react element
  return (
    <SEOPageDataContext.Provider value={{ ...contextState, setContextState }}>
      <PageWrapper {...styleObject}>
        {/* Pass child elements */}
        {error ? <ErrorPage errorMessage={error} /> : mappedComponents}
      </PageWrapper>
    </SEOPageDataContext.Provider>
  );
}
