import React from 'react'
import {
  Header2
} from '@aaa/muse-ds/Header2';
import {
  RichContentWrapper
} from '@aaa/muse-ds/RichContentWrapper';
import {
  TwoColumnSection
} from '@aaa/muse-ds/TwoColumnSection';
import MemberValidationButton from '../special/MemberValidationButton';
import NuxeoRichTextWrapperComponent from '../NuxeoRichTextWrapperComponent';
import { getHeaderFromRichText } from '../../utils/text.utils';
import { logNavigationClick } from '../../utils/logging.utils';
import { SEOPageDataContext } from '../../App';
import { useContext } from "react";
import SectionHiddenIdComponent from '../special/SectionHiddenIdComponent';
export default function WebCompImgContentSection(props) {
  
  var backgroundImage = props.backgroundImage;
  const { alternate, title, content, backgroundColor, layout, imgRadius, imgWidth, imgAspectRatio } = props;
  const defaultBackgroundColor = alternate ? '#FFFFFF' : '#dee0e7';
  const {site,domain,pageName}= useContext(SEOPageDataContext)||{};

  if (!Array.isArray(backgroundImage)) backgroundImage = [backgroundImage];

  const backgroundItems = backgroundImage.map((x, xi) => {
    return { id: xi+"", imgURL: x };
  });

  const header = getHeaderFromRichText(content)

  const customEventData =  {
    "section_container_click": {
      "component_buttonlink":props.button ? props.button.label : "",
      "component_header":header,
      "component_image":backgroundItems.length == 1 ? backgroundItems[0].imgURL : null,
      "component_id":props.nuxeoTitle,
      "destination_url":props?.button?.href,
      "vendor_name":props?.button?.vendorName
    },
  }

  const optionalButtonContent = props.button ? (
    <MemberValidationButton
      ariaLabel={props.button.label}
      label={props.button.label}
      parentName={props.nuxeoTitle}
      newTab={props.newTab}
      customEventType={"section_container_click"}
      customEventData = {customEventData}
      vendorId={props.button.vendorId} 
      requiresMemberValidation={props.button.requiresMemberValidation} 
      href={props.button.href}
      style="NEU_PRIMARY"
    />
  ) : null;

  var mediaObject={
    imgSrc: backgroundItems.length == 1 ? backgroundItems[0].imgURL : null,
    swiperItems: backgroundItems.length > 1 ? backgroundItems : null
  }

  // Make sure we have media details
  if(props.mediaDetails){

    // Make sure we have background ids
    if(props.backgroundIds&&(props.backgroundIds.length>0)){

      // Get the first id
      const mediaId = props.backgroundIds[0];

      // If we have details for the given id, and it's a video type
      if(props.mediaDetails[mediaId] && props.mediaDetails[mediaId].type=="VIDEO"){

        const background0Url  = props.mediaDetails[props.backgroundIds[0]].publicUrl

        if(background0Url){

        // Save 
        mediaObject = {
          "youtubeVideoID":background0Url.split("v=")[1]
        }

        }

      }
    }
  }

  const nuxeoTitle = props.nuxeoTitle

  const navigationLinkNextText = header ? `Image Carousel: Next - ${header}` : `Image Carousel: Next`;
  const navigationLinkPreviousText = header? `Image Carousel: Previous - ${header}` : `Image Carousel: Previous`;

  return (
    <TwoColumnSection
        inset={layout == "fullWidth" ? false : true}
        onSwiperNext={()=>{
            logNavigationClick(site,domain,pageName,navigationLinkNextText,nuxeoTitle).then(()=>{})
        }}
        onSwiperPrev={()=>{
            logNavigationClick(site,domain,pageName,navigationLinkPreviousText,nuxeoTitle).then(()=>{})
        }}
        alternate={alternate}
        imgRadius={imgRadius || null}
        imgWidth={imgWidth || "50%"}
        imgAspectRatio={imgAspectRatio || "4 / 3"}
        {...mediaObject}
        backgroundColor={
            (backgroundColor || { color: defaultBackgroundColor }).color
        }
    >
      <SectionHiddenIdComponent/>
      <RichContentWrapper>

        {(title!=null&&title.trim().length()>0) ? <Header2 serif bold>{title}</Header2> : null}
        
        <NuxeoRichTextWrapperComponent content={content} />
      </RichContentWrapper>
        {optionalButtonContent}
    </TwoColumnSection>
  );
}
