import React from 'react'
import { Button } from "@aaa/muse-ds/Button";
import { Container } from '@aaa/muse-ds/Container'
import { Banner } from "@aaa/muse-ds/Banner";
import { Paragraph } from "@aaa/muse-ds/Paragraph";
import ContentTitleContainerComponent from "../ContentTitleContainerComponent";
import WebCompFormComponent from "./WebCompFormComponent";
import { useState, useContext,useEffect } from "react";
import { SEOPageDataContext } from "../../App";
import ReCAPTCHA from "react-google-recaptcha";
import { GridColumnsContainer } from "@aaa/muse-ds/GridColumnsContainer";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import { getDefaultHost } from "../../utils/ssr.utils";

export default function WebCompForm(props) {

    const {recipients} = props;

    const [recaptchaAuth,setRecaptcahAuth] = useState("")
    const recaptchaRef = React.createRef();
    const pageContext = useContext(SEOPageDataContext);
    const [formData,setFormData] = useState({})
    const [error,setError] = useState(null)
    const [invalidFields,setInvalidRequiredFields] = useState([])
    const [loading,setLoading] = useState(false)
    const [success,setSuccess] = useState(null)

    const resetForm = ()=>{

         // Default with the form id
        var defaultState = {formId:props.uid,pageId:pageContext.uid}

        // Pre-populate the default state with default values.
        props.fields.forEach(prop=>{
            defaultState[prop.label]=prop.defaultValue||"";
        });

        setFormData({...defaultState,pageName:pageContext.pageName,pageUrl:location.href,formName:props.nuxeoTitle,domain:pageContext.domain,site:pageContext.side})
    }


    const submitForm = ()=>{

        var missingRequiredFields = []
        
        props.fields.forEach((x,xi) => {
            if(x.required&&formData[x.label].trim().length==0)missingRequiredFields.push(x.label)
        });

        if(missingRequiredFields.length>0){
            setInvalidRequiredFields(missingRequiredFields)
            setError("The following fields are required: "+missingRequiredFields.join(", "));
            return;
        }
        setInvalidRequiredFields([])

        // Make sure we aren't loading
        if(loading)return;

        // Set us as loading
        setLoading(true);        

        const content=JSON.stringify(formData);

        fetch(`${getDefaultHost(props.domain)}/form?token=${recaptchaAuth}`,{
            "method":"Post",
            "body":JSON.stringify(formData),
            "headers":{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization":"Bearer "+props.auth
            },
            
        }).then(e=>e.json())
            .then(e=>{
                if(e.status!="success"){
                    
                    setError("An error has occurred while trying to submit your form. "+e.error+". Please try again. If this error continues, try refreshing the page.")
                }else{

                    setSuccess(`${props.title} Successfully submitted! Thank you for your time.`)
                    setError(null);
                }
        }).catch(e=>{
            setError("An error has occurred while trying to submit your form. Please try again. If this error continues, try refreshing the page.")
        }).finally(()=>{
            
            // We're done loading
            setLoading(false)

        })
    }

    const onComponentChange = (newValue)=>{
        setFormData({...formData,...newValue})
    }

    const onRecaptchaChange = (a)=>{

        setRecaptcahAuth(a)
    }

    useEffect(resetForm,[]);

    var formRows = [];
    var currentFormRow = [];

    {props.fields.forEach((x,xi) => {

        // Text areas will be full width
        if(x.type=="textArea"){

            if(currentFormRow.length>0){
                
                // push the current row as is
                formRows.push(<GridColumnsContainer alignItemsBaseline={true} columnL="6"columnS="12">{[...currentFormRow]}</GridColumnsContainer>)
            }

            // Push the component as is
            formRows.push(<WebCompFormComponent invalid={invalidFields.includes(x.label)} onChange={onComponentChange} {...x} />)
            currentFormRow=[];
        }else{

            // Add to the current row
            currentFormRow.push(<WebCompFormComponent alignItemsBaseline={true} invalid={invalidFields.includes(x.label)} onChange={onComponentChange} {...x} />)

            // if the row is full (2 items)
            // Or if this is the last item
            if(currentFormRow.length==2||xi==props.fields.length-1){
                // push the current row as is
                formRows.push(<GridColumnsContainer alignItemsBaseline={true} columnL="6"columnS="12">{[...currentFormRow]}</GridColumnsContainer>)
                currentFormRow=[];

            }
        }
    })}

    return <ContentTitleContainerComponent title={props.title} content={props.content}>
        
    <SectionHiddenIdComponent/>
        <Container width="980">
            {(error&&!success) ? <Banner
            iconName="ui-circle-outline--error"
            message={error}
            showBanner
            showCloseButton
            closeAction={()=>setError(null)}
            title={"Oops: An error has occurred!"}></Banner> : null }

            {/* If we have success, show the success message. */}
            {success ? <Paragraph>
                {success}
            </Paragraph>:
            <>
            <div>
                 {/* If we DO NOT have success, show the form. */}
                {formRows}
                </div>
                <div>
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6Ld-wK4gAAAAALaEkY10Kv6Dpb6uxU3NR-vdvzrV" onChange={onRecaptchaChange}   />
                </div>
                <div style={{"marginTop":16}}>
                    <Button style="NEU_PRIMARY" disabled={loading||recaptchaAuth==null||recaptchaAuth.trim().length==0} loading={loading} action={submitForm}>{props.submitButtonLabel}</Button>
                    <Button style="NEU_SECONDARY" action={resetForm} disabled={loading}>{props.resetButtonLabel}</Button>
                </div>
                </>
            }
        </Container>
    </ContentTitleContainerComponent>
}