
export const PRODUCTION = (process.env.PRODUCTION || "false").toLowerCase()=="true"

export const NUXEO_ENVIRONMENT_DOMAIN = process.env.NUXEO_ENVIRONMENT_DOMAIN || "csp.aaa.com";

export const DEFAULT_DOMAIN = process.env.DEFAULT_DOMAIN || "localhost:3000"

export const RECAPTCHA_SECRET = process.env.RECAPTCHA_SECRET || "6Ld-wK4gAAAAAE1v3AyB2WA3hgzfWeg87qnlekUX"

export const DEFAULT_HOST = process.env.DEFAULT_HOST || (DEFAULT_DOMAIN=="//localhost:3000" ? `http://localhost:3000`: `https://${DEFAULT_DOMAIN}`)

export const AB_TESTING_TARGET_REQUEST_ID = process.env.AB_TESTING_TARGET_REQUEST_ID || 'target-nuxeo-ab';

export const AB_TESTING_ENABLED = (process.env.AB_TESTING_ENABLED||"false").trim().toLowerCase()=="true";

export const AB_TESTING_ORGANIZATION_ID = process.env.AB_TESTING_ORGANIZATION_ID || '';

export const AB_TESTING_PROPERTY_TOKEN = process.env.AB_TESTING_PROPERTY_TOKEN || '';

export const AWS_API_GATEWAY = process.env.AWS_API_GATEWAY || "api.national.aaa.com"

export const SEOGATEWAY_DOMAIN = process.env.SEOGATEWAY_DOMAIN || (AWS_API_GATEWAY+"/content/seo/pages")

export const COGNITO_AUTHENTICATION = process.env.COGNITO_AUTHENTICATION || "";

export const AAA_TOMCAT_DOMAIN = process.env.AAA_TOMCAT_DOMAIN || 'mestag.national.aaa.com';
export const AAA_TOMCAT_HOST = process.env.AAA_TOMCAT_HOST || `https://${AAA_TOMCAT_DOMAIN}`;

export const AAA_WEBSERVER_DOMAIN = process.env.AAA_WEBSERVER_DOMAIN || 'certc.national.aaa.com';
export const AAA_WEBSERVER_HOST = process.env.AAA_WEBSERVER_HOST || `https://${AAA_WEBSERVER_DOMAIN}`;

export const COOKIE_MAX_AGE = process.env.COOKIE_MAX_AGE || 1000 * 60 * 60 * 2

export const SESSION_SECRET = process.env.SESSION_SECRET || "secret"


export const STATIC_SSR_PUBLIC_PATH = process.env.STATIC_SSR_PUBLIC_PATH || (process.env.LOCAL_PUBLIC_PATH || process.env.PUBLIC_PATH)

export const public_path = process.env.NODE_ENV === 'development' ? ''  : STATIC_SSR_PUBLIC_PATH;

export const AAA_REST_HOST =   process.env.AAA_REST_HOST || 'mestagservices.national.aaa.com';

export const JWT_SIGNING_SECRET = process.env.JWT_SIGNING_SECRET||"secret"