import React from 'react'
import {HighlightBar} from'@aaa/muse-ds/HighlightBar'
import SectionHiddenIdComponent from '../special/SectionHiddenIdComponent';

export default function WebCompHighlight(props){

  const {textColor,backgroundColor}= props;

    const items = (props["items"]||[]).map(x=>{return {
      "ariaLabel":x.label,
      "label":x.label,
      "hideIcon":x.hideIcon,
      "iconName": x.icon,
      "href":x.href
    }})

    return  <>
    <SectionHiddenIdComponent/>
      <HighlightBar highlightBarItems={items} highlightBarBackground={backgroundColor} IconTextColor={textColor} />
    </>
}