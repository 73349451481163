import React from 'react'
import { Accordion} from "@aaa/muse-ds/Accordion"
import { Container } from "@aaa/muse-ds/Container"
import { RichContentWrapper } from "@aaa/muse-ds/RichContentWrapper"
import NuxeoRichTextWrapperComponent from "../NuxeoRichTextWrapperComponent";
import ContentTitleContainerComponent from "../ContentTitleContainerComponent";
import { logNavigationClick } from "../../utils/logging.utils";
import { SEOPageDataContext } from "../../App";
import { useContext } from "react";

export default function WebCompAccordionSection(props) {
  const background = props['background'] || {color:null};
  const { items, title, nuxeoTitle, content, faq } = props
  const {site,domain,pageName} = useContext(SEOPageDataContext) || {"site": "development","domain":"www.aaa.com","pageName":""}

  const itemsList  = (items||[]);


  return <ContentTitleContainerComponent background={background.color} content={content}>
    
    <input type="hidden" id={`Section ${props.sectionIndex} - ${props.nuxeoTitle}`} value={""}/>
      <Container width="980">
      {
        itemsList.map((x, i) => {
          return (<Accordion
            accordionId={i + ""}
            key={nuxeoTitle + "-accordion-item" + i}
            faq={x.faq == true ? true : false}
            accordionOnClick={(e,active)=>{e.preventDefault();e.stopPropagation();logNavigationClick(site,domain,pageName,"Accordion "+(active?"Close":"Open")+": "+x.title,props.nuxeoTitle)}}
            title={x.title}>
            <RichContentWrapper>
            <NuxeoRichTextWrapperComponent content={x.content}/>
            </RichContentWrapper>
          </Accordion>)
        })
      }
      </Container>
    </ContentTitleContainerComponent>
}