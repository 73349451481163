import React from 'react'
import { ContentBanner } from "@aaa/muse-ds/ContentBanner";
import { Header1 } from "@aaa/muse-ds/Header1";
import { Paragraph } from "@aaa/muse-ds/Paragraph";
import { Container } from "@aaa/muse-ds/Container";
import MemberValidationButton from "../special/MemberValidationButton";
import parse from 'html-react-parser';
import { getFlattendTextFromRichText, getHeaderFromRichText } from "../../utils/text.utils";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import { LazyImage } from '@aaa/muse-ds/LazyImage';
export default function WebCompContentBanner(props){
  const title = (props.title && props.title!="null")?props.title : "";
  const subtitle = (props.subtitle && props.subtitle!="null")?props.subtitle : "";

    /**
     * The wrapping span is neccessary.
     * This method won't operate correctly when content doesn't have a parent.
     * This can be the case with nuxeo content from time to time.
     */
    var titleJSX = parse("<span>"+title+"</span>");
    var subtitleJSX = parse("<span>"+subtitle+"</span>");
    var contentJSX = parse("<span>"+props.content+"</span>");

    const header = getFlattendTextFromRichText(props.title) || props.title
    const subheader = getFlattendTextFromRichText(props.subtitle) || props.subtitle
    
    const customEventData =  {
      "hero_banner_click": {
        "component_buttonlink":props.button ? props.button.label : "",
        "component_header":header,
        "component_subheader":subheader,
        "component_image":props.backgroundImage,
        "component_id":props.nuxeoTitle,
        "destination_url":props?.button?.href,
        "vendor_name":props?.button?.vendorName
      },
    }

    return <ContentBanner
      height={props.height || "60vh"}
      imgAlt= {props.title}
      loading="eager"
      id="seo-content-banner"
      imgSrc={props.backgroundImage}
      overlay={props.hideOverlay ? false : true}
    >
      
    <SectionHiddenIdComponent/>
    {props.iconImage ? (
        <LazyImage loading="eager" src={props.iconImage} maxWidth={props.iconMaxWidth}/>
    ) : null}
    <Header1 bold color="#ffffff" serif shadow>
      {titleJSX.props.children}
    </Header1>
    <Paragraph color="#ffffff" fontSize="M" shadow spacing>
      {subtitleJSX.props.children}
    </Paragraph>

    {/* Check If we have content, and it's not empty */}
    {(props.content!=null&&props.content.trim().length>0)?<>
      <Container width="980" noPadding>
        <Paragraph color="#ffffff" shadow>
          {contentJSX.props.children}
        </Paragraph>
      </Container> <br/>
    </> : null}
   
   {/* Check if we have a button content and label */}
      {props.button && props.button.label && props.button.label.trim().length>0 ? (
        <MemberValidationButton
          parentName={props.nuxeoTitle}
          vendorId={props.button.vendorId}
          customEventType={"hero_banner_click"}
          customEventData={customEventData}
          requiresMemberValidation={props.button.requiresMemberValidation}
          href={props.button.href}
          label={props.button.label}
        />
      ) : null}
    </ContentBanner>
}
